<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <task-form
        v-if="isFormActive"
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un tâche') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </task-form>
    </v-dialog>
    <!-- task total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Tâches") }}<v-chip
          outlined
          class="ml-2"
        >
          {{ totalItems }}
        </v-chip>
        <v-spacer />
        <v-btn
          color="primary"
          @click="openDialog(false)"
        >
          {{ $t("Ajouter une tâche") }}
        </v-btn>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table"
        fixed-header
        height="500"
        dense
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="false"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- actions -->
        <template #[`item.type`]="{item}">
          <div>
            <span v-if="item.type === 'text'">Traitement texte</span>
            <span v-if="item.type === 'cover'">Traitement couverture</span>
            <span v-if="item.type === 'publication'">Parution</span>
            <span v-if="item.type === 'communication'">Communication</span>
          </div>
        </template>
        <template #[`item.task_date`]="{item}">
          <div>
            {{ formatDate(item.task_date || null, { month: 'short', year: 'numeric' }) }}
          </div>
        </template>
        <template #[`item.actions`]="{item}">
          <v-btn
            v-if="item.publication"
            icon
            small
          >
            <v-icon
              size="20"
              @click="openDialog(item)"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="item.publication"
            icon
            small
            color="error"
          >
            <v-icon
              size="20"
              color="error"
              @click="confirmDelete(item)"
            >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import TaskForm from './TaskForm.vue'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import itemStoreModule from '@/views/apps/publications/itemStoreModule'
import HeaderFilters from '@/components/HeaderFilters'

export default {
  components: {
    HeaderFilters,
    RowsPerPage,
    TaskForm,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'task'
    const superiors = ref([])
    const assistants = ref([])

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_PUBLICATION_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)) store.registerModule(USER_APP_STORE_PUBLICATION_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)
    })

    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchItems,
      fetchRoles,
      filters,
    } = controller()

    axios
      .get('/listWorkers', {
        params: {
          term: '',
          type: 'tasks',
          rowsPerPage: 100,
          user: JSON.parse(localStorage.getItem('userData')).id || null,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value/* .filter(el => el.role_name === 'assistant_indirect') */
        }
        const indexC = filters.value.findIndex(ele => ele.value === 'assistant_cover.code')
        if (indexC > 0) {
          filters.value[indexC].filter.values = assistants.value.filter(el => el.role_name === 'assistant_indirect')
        }
        const indexGest = filters.value.findIndex(ele => ele.value === 'gestion.code')
        if (indexGest > 0) {
          filters.value[indexGest].filter.values = [
            { header: 'Gestionnaire', disabled: true },
            ...assistants.value.filter(el => el.role_name === 'gestionnaire'),
            { header: 'Assistants Direct', disabled: true },
            ...assistants.value.filter(el => el.role_name === 'assistant_direct')]
        }
        const indexPub = filters.value.findIndex(ele => ele.value === 'assistant_pub.code')
        if (indexPub > 0) {
          filters.value[indexPub].filter.values = assistants.value.filter(el => el.role_name === 'assistant_publication' || el.role_name === 'service_publications')
        }
      })

    fetchRoles()

    return {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      formatDate,
      filters,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
