<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Attribuer le dossier") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <template v-if="loadingManuscript">
          <v-row
            align="center"
            justify="center"
            class="pt-5"
          >
            <v-col
              cols="12"
              class="text-center"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              {{ $t("Chargement") }}
            </v-col>
          </v-row>
        </template>
        <v-slide-y-transition v-else>
          <v-row
            class="pt-5"
          >
            <v-col
              v-if="item.manuscript && item.author"
              cols="12"
            >
              <div class="d-flex align-center">
                <v-avatar
                  :color="item.author.avatar ? '' : 'primary'"
                  :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.author_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.author.full_name }}
                  </router-link>
                  <small>{{ item.manuscript.title }}</small>
                </div>
              </div>
            </v-col>
            <!--            <v-col cols="12">
              <v-autocomplete
                v-model="item.gestion_id"
                :label="$t('Gestionnaire')"
                :error-messages="errorMessages.gestion"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                :items="users.filter(el => el.role_name === 'gestionnaire' || el.role_name === 'assistant_direct')"
                no-data-text="Aucun élément disponible"
                clearable
              >
                <template #item="{ item }">
                  <div>
                    {{ item.name }} - {{ item.code }} <span class="float-right">{{ item.nb_tasks }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>-->
            <v-col
              v-if="!item.id"
              cols="12"
            >
              <v-autocomplete
                v-model="item.publication_id"
                :rules="[validators.required]"
                :error-messages="errorMessages.title"
                :label="$t('Publication')"
                item-text="id"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                :items="publications"
                :loading="isLoadingPublications"
                :search-input.sync="search"
                clearable
                :readonly="item.id"
              >
                <template #item="{ item }">
                  <span>{{ item.id }} - {{ item.author.full_name }}</span>
                  <!--                  <AvatarName :options="{avatar: item.author.avatar, label: item.author.full_name, publication: item.id }" />-->
                </template>
                <template #selection="{ item }">
                  <span>{{ item.id }} - {{ item.author.full_name }}</span>
                  <!--                  <AvatarName :options="{avatar: item.author.avatar, label: item.author.full_name, publication: item.id }" />-->
                </template>
              </v-autocomplete>
            </v-col>
            <!--            <v-col
              v-else
              cols="6"
            >
              <v-text-field
                v-model="item.publication_id"
                dense
                outlined
                hide-details
                readonly
                label="Dossier"
              />
            </v-col>-->
            <v-col
              v-if="item.id"
              cols="6"
            >
              <v-text-field
                v-if="item.type === 'text'"
                dense
                outlined
                hide-details
                readonly
                value="Traitement texte"
                label="Type"
              />
              <v-text-field
                v-if="item.type === 'cover'"
                dense
                outlined
                hide-details
                readonly
                value="Traitement couverture"
                label="Type"
              />
              <v-text-field
                v-if="item.type === 'publication'"
                dense
                outlined
                hide-details
                readonly
                value="Parution"
                label="Type"
              />
              <v-text-field
                v-if="item.type === 'communication'"
                dense
                outlined
                hide-details
                readonly
                value="Communication"
                label="Type"
              />
            </v-col>

            <v-col
              v-if="item.publication_id && !item.id"
              cols="12"
            >
              <v-autocomplete
                v-model="item.type"
                :label="$t('Type')"
                :error-messages="errorMessages.type"
                outlined
                item-text="label"
                item-value="id"
                dense
                hide-details="auto"
                :items="types.filter(ele => item.id || publications.find(ele1 => ele1.id === item.publication_id) && !publications.find(ele1 => ele1.id === item.publication_id).tasks.map(m => m.type ).includes(ele.id))"
                no-data-text="Aucun élément disponible"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="item.publication_id && item.id"
              cols="12"
            >
              <v-autocomplete
                v-model="item.assistant_id"
                :label="$t('Assignée à')"
                :error-messages="errorMessages.assistant"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                :items="groupedUsers"
                no-data-text="Aucun élément disponible"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="!item.id && item.publication_id"
              cols="12"
            >
              <v-autocomplete
                v-model="item.assistant_id"
                :label="$t('Assignée à')"
                :error-messages="errorMessages.assistant"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                :items="groupedUsers"
                no-data-text="Aucun élément disponible"
              >
                <template #item="{ item }">
                  <span>{{ item.name }}({{ item.role_label }})</span>
                </template>
                <template #selection="{ item }">
                  <span>{{ item.name }} - {{ item.role_label }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="!item.id && item.publication_id"
              cols="12"
            >
              <date-picker
                v-model="item.task_date"
                :rules="[validators.required]"
                :label="$t('Date/période de traitement')"
              />
            </v-col>
          </v-row>
        </v-slide-y-transition>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Confirmer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      loadedManuscripts,
      roles,
      fetchRoles,
      snackBarMessage,
    } = controller()
    const {
    } = authorsController()
    const users = ref([])
    const isLoadingUsers = ref(false)
    const errorMessages = ref({ gestion: null, assistant: null })
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const publications = ref([])
    const isLoadingAuthors = ref(false)
    const loadingManuscript = ref(true)

    const search = ref(null)

    const types = ref([
      { id: 'communication', label: 'Communication' },
      { id: 'publication', label: 'Parution' },
      { id: 'text', label: 'Traitement texte' },
      { id: 'cover', label: 'Traitement couverture' },
    ])
    const manuscript = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    const onSubmit = () => {
      if (valid.value) {
        // if(!item.value.task_date) {
        //   isSnackbarBottomVisible.value = true
        //   snackBarMessage.value = 'Veuillez entrer la date'
        //   return false
        // }
        if (item.value.auto && /*! item.value.gestion_id && */ !item.value.assistant_id) {
          snackBarMessage.value = 'Sélectionnez au moins un gestionnaire ou un assistant'
          isSnackbarBottomVisible.value = true
          errorMessages.value.gestion = 'Sélectionnez au moins un gestionnaire ou un assistant'
          errorMessages.value.assistant = 'Sélectionnez au moins un gestionnaire ou un assistant'
        } else {

        }
        loading.value = true
        const url = '/tasks'
        axios.post(url, {
          id: item.value.id,
          publication_id: item.value.publication_id,
          gestion_id: item.value.gestion_id,
          assistant_id: item.value.assistant_id,
          task_date: item.value.task_date,
          type: item.value.type,
        }).then(response => {
          loading.value = false
          if (response && (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false'))) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...response.data.errors }
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)
          fetchItem()
          emit('refetch-data')
        }).catch(error => {
          loading.value = false
          console.log('error', error)
          if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }
        })
      } else {
        validate()
      }
    }
    const onSearch = val => {
      isLoadingPublications.value = true
      axios
        .get('/task/publications', { params: { term: val, rowsPerPage: 50 } })
        .then(response => {
          isLoadingPublications.value = false
          for (let i = 0; i < response.data.length; i++) {
            const p = response.data[i]
            const indexU = publications.value.findIndex(el => el.id === p.id)
            if (p.id && indexU < 0) {
              publications.value.push(p)
            }
          }
        }).then(() => {
          isLoadingPublications.value = false
        })
    }
    const loadUsers = val => {
      isLoadingUsers.value = true
      axios
        .get('/listWorkers', { params: { term: val, rowsPerPage: 50, type: 'tasks' } })
        .then(response => {
          isLoadingUsers.value = false
          for (let i = 0; i < response.data.length; i++) {
            const p = response.data[i]
            const indexU = users.value.findIndex(el => el.id === p.id)
            if (p.id && indexU < 0) {
              users.value.push(p)
            }
          }
        }).then(() => {
          isLoadingUsers.value = false
        })
    }

    loadUsers('')

    // onSearch('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.toString().trim().length < 3 || isLoadingPublications.value) return ''
      onSearch(val.toString())
    })
    watch(item, val =>

    // fetchItem()

      '',
    { deep: true })
    const fetchItem = () => {
      const man = loadedManuscripts.value.find(ele => ele.id === item.value.publication_id)
      if (man) {
        console.log('hey')
        item.value.manuscript = man
        loadingManuscript.value = false
      } else {
        if (!item.value.publication || !item.value.publication.manuscript_id) {
          loadingManuscript.value = false
          console.log('no publication')

          return false
        }
        loadingManuscript.value = true
        axios.get(`/manuscripts/${item.value.publication.manuscript_id}`).then(response => {
          loadingManuscript.value = false
          if (response.data && response.data.id) {
            item.value.manuscript = response.data
            loadedManuscripts.value.push(response.data)
          }
        }).then(() => {
          loadingManuscript.value = false
        }).catch(() => {
          loadingManuscript.value = false
        })
      }
    }
    fetchRoles()
    fetchItem()
    const groupedUsers = computed({
      get: () => {
        // all users if just creating task
        if (!item.value.id) {
          return users.value
        }

        // const general_users = users.value.filter(ele => !roles.includes(ele.role_name))
        const values = []

        let code = `can_${item.value.type}`
        if (item.value.type === 'publication') {
          code = 'can_parution'
        }
        for (let i = 0; i < roles.value.length; i++) {
          if (roles.value[i][code]===1) {
            const us = users.value.filter(ele => ele.roles_name.includes(roles.value[i].name))
            if (us.length) {
              values.push({ header: roles.value[i].display_name }, ...users.value.filter(ele => ele.roles_name.includes(roles.value[i].name)))
            }
          }
        }

        return values

        // const roles = ['supervisor', 'gestionnaire', 'assistant_direct', 'assistant_indirect', 'service_publications', 'Graphisme']
        const supervisors = users.value.filter(ele => ele.roles_name.includes('supervisor'))
        const gestionaires = users.value.filter(ele => ele.roles_name.includes('gestionnaire'))
        const assistant_indirect = users.value.filter(ele => ele.roles_name.includes('assistant_indirect'))
        const assistant_direct = users.value.filter(ele => ele.roles_name.includes('assistant_direct'))
        const service_publications = users.value.filter(ele => ele.roles_name.includes('service_publications'))
        const Graphisme = users.value.filter(ele => ele.roles_name.includes('Graphisme'))
        const service_communication = users.value.filter(ele => ele.roles_name.includes('service_communication'))

        if (item.value.type === 'text' || item.value.type === 'cover') {
          if (gestionaires.length > 0) {
            values.push({ header: 'Superviseurs' }, ...supervisors)
          }
          if (gestionaires.length > 0) {
            values.push({ header: 'Gestionnaire' }, ...gestionaires)
          }
          if (assistant_indirect.length > 0) {
            values.push({ header: 'Assistant Indirect' }, ...assistant_indirect)
          }
          if (assistant_direct.length > 0) {
            values.push({ header: 'Assistant Direct' }, ...assistant_direct)
          }
        }
        if (item.value.type === 'cover') {
          if (Graphisme.length > 0) {
            values.push({ header: 'Graphiste' }, ...Graphisme)
          }
        }
        if (item.value.type === 'publication') {
          values.push({ header: 'Assistant Publication' }, ...service_publications)
        }
        if (item.value.type === 'communication') {
          values.push({ header: 'Assitant Communication' }, ...service_communication)
        }

        if (!item.value.id) {
          return users.value
        }

        return values
      },
    })

    return {
      groupedUsers,
      form,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      loadingManuscript,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      types,
      publications,
      isLoadingAuthors,
      isLoadingUsers,
      onSubmit,
      modalDate,
      dialogDate,
      item,
      users,
      valid,
      dialog,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
